import API from "./core";

export const fetchInstanceLogs = async (params?: any) => {
    try{
        return API.get('/rest/logs', { params })
            .then(
                response => {
                    if(response.data?.status === 'SUCCESS'){
                        return response.data.data;
                    }else{
                        return null;
                    }
                },
                error => {
                    console.log(error);
                    return null;
                }
            );
    }catch(error){
        return error;
    }
};

export const fetchInstanceLogsCsv = async ( params?: any ) => 
    API.get('/rest/logs', { params, responseType: 'arraybuffer', headers: { 'Accept': 'application/csv' } });
