import { library } from '@fortawesome/fontawesome-svg-core';

import {
    // faCheck,
    // faEdit,
    faEraser,
    // faExclamationCircle,
    // faExclamationTriangle,
    faFileCsv,
    // faHospital,
    // faPlus,
    // faSave,
    faSearch,
    faTachometerAlt,
    faTerminal,
    // faTimes,
    // faTrash,
	// faCog
} from '@fortawesome/free-solid-svg-icons';

// import {
//     faIslandTropical as fasIslandTropical,
// 	faEye as fasEye,
//     faEyeSlash as fasEyeSlash
// } from '@fortawesome/pro-solid-svg-icons';

library.add(
    // faCheck,
    // faEdit,
    faEraser,
    // faExclamationCircle,
    // faExclamationTriangle,
    faFileCsv,
    // faHospital,
    // faPlus,
    // faSave,
    faSearch,
    faTachometerAlt,
    faTerminal
    // faTimes,
    // faTrash,
	// faCog

    // fasIslandTropical as any,fasEye as any, fasEyeSlash as any
);