import React from 'react';
import ApplicationRouter from '../../../router';

import { Sidebar } from '@optimuminterfaces/revex-react-components/dist/components/index';

import '../../../configs/awesomeFontLibrary';

import './App.scss';
import styles from './App.module.scss';

export interface AppProps {
    history?: any;
    showMenu?: boolean;
    Container?: any
};

const renderSidebar = () => {
    return (
        <Sidebar>
            <Sidebar.Menu name="Dashboard" icon="tachometer-alt" url="#/" />
            <Sidebar.Menu name="Logs" icon="terminal" url="#/logsMF" />
        </Sidebar>
    );
};

function App({ history, showMenu, Container = 'div' }: AppProps) {
    return (
        <>
            <div className={`${styles['app']} ${showMenu ? styles['sidebar'] : ''} uatu-app`}>
                {showMenu &&
                    <Container className={styles['container-sidebar']}>
                        {renderSidebar()}
                    </Container>
                }

                <Container className={styles['container-body']}>
                    <ApplicationRouter history={history} />
                </Container>
            </div>
        </>
    );
};

export default App;
