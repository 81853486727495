import React from 'react';

import { Route, HashRouter as Router, Routes } from 'react-router-dom';
import { createHashHistory, HashHistory } from 'history';

import LogIndex from './components/pages/Log/Index/Index';

const defaultHistory = createHashHistory();

interface ApplicationRouterProps {
    history: HashHistory
};

const ApplicationRouter = ({ history = defaultHistory }: ApplicationRouterProps) => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<div>Dashboard</div>} />
                <Route path="/logsMF" element={ <LogIndex />} />

                {process.env.NODE_ENV === 'development' &&
                    <Route path="/auth" element={<>Auth</>} /> 
                } 
            </Routes>
        </Router>  
    );
};

export default ApplicationRouter;
