import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Button, DateTimePicker, Input, Loading, Pagination, Panel, Select } from "@optimuminterfaces/revex-react-components/dist/components";

import LogList from "../../../organisms/Log/List/List";
import { Log } from "../../../../models/Log";

import { translation as t, getCurrentLanguage } from "@optimuminterfaces/revex-react-components/dist/translation/index";
import { downloadCsv } from '@optimuminterfaces/revex-react-components/dist/utils/FileUtil';

import { fetchInstanceLogs, fetchInstanceLogsCsv } from "../../../../services/log.service";

import styles from './Index.module.scss';

interface LogIndexProps {
    className?: string,
    Container?: any,
};

interface LogData {
    logs?: Log[],
    logsQuantity?: any,
};

const LOCATION_OPTIONS = [
    { key: 'all', value: 'Todos' },
    { key: 'revex', value: 'Revex' },
    { key: 'revex-api', value: 'Revex API' },
    { key: 'shadowcat', value: 'Ausências e Feriados' },
    { key: 'shadowcat-api', value: 'Ausências e Feriados - API' },
    { key: 'cable', value: 'Ponto' },
    { key: 'birdman', value: 'Assinador' }
];

const LogIndex = ({
    className,
    Container = 'div'
}: LogIndexProps) => {

    const logsPerPageOptions = [
        { key: '5', value: `5 ${t('GENERIC.TEXT.LOGS')} ${t('GENERIC.TEXT.PER_PAGE')}`},
        { key: '10', value: `10 ${t('GENERIC.TEXT.LOGS')} ${t('GENERIC.TEXT.PER_PAGE')}`},
        { key: '20', value: `20 ${t('GENERIC.TEXT.LOGS')} ${t('GENERIC.TEXT.PER_PAGE')}`},
        { key: '30', value: `30 ${t('GENERIC.TEXT.LOGS')} ${t('GENERIC.TEXT.PER_PAGE')}`},
        { key: '50', value: `50 ${t('GENERIC.TEXT.LOGS')} ${t('GENERIC.TEXT.PER_PAGE')}`},
        { key: '100', value: `100 ${t('GENERIC.TEXT.LOGS')} ${t('GENERIC.TEXT.PER_PAGE')}`}
    ];

    const [loading, setLoading] = useState(false);
    const [logData, setLogData] = useState<LogData>({});

    const [filters, setFilters] = useState<any | null>({ pageNumber: 1, quantityPerPage: 30 });

    const [logTypeOptions, setLogTypeOptions] = useState<any | null>(null);
    const [selectedOptions, setSelectedOptions] = useState<any>({
        'quantityPerPage': logsPerPageOptions[4]
    });

    const loadInstanceLogs = (filters: any) => {
        setLoading(true);

        (async () => {
            const jsonReturn = await fetchInstanceLogs({ ...filters });
            if(!!jsonReturn){
               setLogData(jsonReturn); 

                if(jsonReturn.logTypes && !logTypeOptions){
                    setLogTypeOptions(jsonReturn.logTypes
                        .map((logType: any) => ({ 
                            key: logType.id, 
                            value: logType.name, 
                            selected: false 
                        })));
                }
            }
            setLoading(false);
        })();
    };

    const internalHandleChanges = ({ name , value }: any) => {
        switch(name){
            case 'Filters.input':
                value.preventDefault?.();
                value.stopPropagation?.();
                setFilters((filters: any) => ({ ...filters, [value.target.name]: value.target.value }));
                break;

            case 'Filters.logTypeId':
                setFilters((filters: any) => ({ ...filters, 'logTypeId': value }));
                setSelectedOptions((selectedOptions: any) => ({ 
                    ...selectedOptions, 
                    'logType': logTypeOptions.filter((option: any) => (value === option.key))?.[0] 
                }));
                break;

            case 'Filters.location':
                if(value !== 'all'){
                    setFilters((filters: any) => ({ ...filters, 'location': value }));
                    setSelectedOptions((selectedOptions: any) => ({ 
                        ...selectedOptions, 
                        'location': LOCATION_OPTIONS.filter((option: any) => (value === option.key))?.[0] 
                    }));
                }else{
                    setFilters((filters: any) => {
                        const { location, ...others } = filters;
                        return others;
                    });

                    setSelectedOptions((selectedOptions: any) => {
                        const { location, ...others } = selectedOptions;
                        return others;
                    });
                }
                break;
            
                case 'Filters.quantityPerPage':
                setFilters((filters: any) => ({ ...filters, 'quantityPerPage': parseInt(value) }));
                setSelectedOptions((selectedOptions: any) => ({ 
                    ...selectedOptions, 
                    'quantityPerPage': logsPerPageOptions.filter((option: any) => (value === option.key))?.[0] 
                }));
                break;

            case 'Filters.created':
                setFilters((filters: any) => ({ ...filters, 'created': value }));
                break;

            case 'Filters.clear':
                value?.preventDefault();
                setFilters({ pageNumber: 1, quantityPerPage: 30 });
                setSelectedOptions({ 'quantityPerPage': logsPerPageOptions[4] });
                loadInstanceLogs({ pageNumber: 1, quantityPerPage: 30 });
                break;

            case 'Filters.search':
                value?.preventDefault();
                value?.stopPropagation();
                loadInstanceLogs(filters);
                break;

            case 'Button.downloadCsv':
                value?.preventDefault();
                value?.stopPropagation();
                (async () => {
                    setLoading(true);
                    let fileName = 'instace_logs.csv'; 
                    const language = (getCurrentLanguage() || 'pt-BR').toLowerCase();
                    switch(language){
                        case 'pt-br':
                        case 'pt-pt':
                            fileName = 'Relatório de logs.csv';
                            break;
                    }
    
                    const response: any = await fetchInstanceLogsCsv();
    
                    downloadCsv(response.data, fileName);
                    setLoading(false);
                })();
                break;

            default:
                console.log(name, value);
        }
    };

    useEffect(() => {
        loadInstanceLogs(filters);
    }, [filters?.pageNumber]);

    return (
        <Container className={`${className || ''} ${styles['log-index-rt']}`.trim()}>
            <Panel title={t('GENERIC.TEXT.FILTERS')}
                actions={[{
                    title: t('GENERIC.BUTTON.CLEAR.TEXT'),
                    outlined: true,
                    icon: 'eraser',
                    disabled: loading,
                    action: (value) => {
                        internalHandleChanges({ name: 'Filters.clear', value });
                    }
                }]}>
                <>
                    <Container className={styles['log-index-filters']}
                        title={t('GENERIC.TEXT.FILTERS')}>

                        <DateTimePicker
                            name="created"
                            type="date"
                            fullWidth
                            mask
                            title={t('GENERIC.TEXT.DATE')}
                            value={filters.created}
                            handleChange={(value) => internalHandleChanges({ name: 'Filters.created', value })} />

                        <Input className={styles['filters-input']}
                            name='createdBy'
                            fullWidth
                            title={t('UATU.TEXT.CREATED_BY')}
                            placeholder={t('UATU.TEXT.CREATED_BY')}
                            value={filters['createdBy'] || ''}
                            handleChange={(value) => internalHandleChanges({ name: 'Filters.input', value })} />

                        <Input className={styles['filters-input']}
                            name='description'
                            fullWidth
                            title={t('GENERIC.TEXT.DESCRIPTION')}
                            placeholder={t('GENERIC.TEXT.DESCRIPTION')}
                            value={filters['description'] || ''}
                            handleChange={(value) => internalHandleChanges({ name: 'Filters.input', value })} />

                        <Select className={styles['select-log-type']}
                            name='logTypeId'
                            fullWidth
                            title={t('GENERIC.TEXT.TYPE')}
                            placeholder={t('GENERIC.TEXT.TYPE')}
                            options={logTypeOptions || []}
                            value={selectedOptions?.logType?.value}
                            handleChange={(value) => internalHandleChanges({ name: 'Filters.logTypeId', value })} />

                        <Select className={styles['select-log-location']}
                            name='location'
                            fullWidth
                            title={t('UATU.TEXT.LOCATION')}
                            placeholder={t('UATU.TEXT.LOCATION')}
                            options={LOCATION_OPTIONS}
                            value={selectedOptions?.location?.value}
                            handleChange={(value) => internalHandleChanges({ name: 'Filters.location', value })} />

                        <Select className={styles['select-log-per-page']}
                            name='quantityPerPage'
                            fullWidth
                            title={t('GENERIC.TEXT.QUANTITY')}
                            placeholder={t('GENERIC.TEXT.QUANTITY')}
                            options={logsPerPageOptions}
                            value={selectedOptions?.quantityPerPage?.value}
                            handleChange={(value) => internalHandleChanges({ name: 'Filters.quantityPerPage', value })} />

                        <Button title={t('GENERIC.BUTTON.SEARCH.TEXT')}
                            icon='search'
                            outlined
                            loading={loading}
                            action={(value) => internalHandleChanges({ name: 'Filters.search', value })} />
                    </Container>
                </>
            </Panel>

            <Panel title={t('GENERIC.TEXT.LOGS')}
                actions={[{
                    title: t('GENERIC.BUTTON.DOWNLOAD.TEXT_WITH_ARGS', undefined, 'CSV'),
                    outlined: true,
                    icon: 'file-csv',
                    disabled: loading,
                    action: (value) => {
                        internalHandleChanges({ name: 'Button.downloadCsv', value });
                    }
                }]}>
                <>
                    {((loading === true) && <Loading />) ||
                        <Container className={styles['log-index-logs-container']}>
                            <Pagination.Compact 
                                currentPage={filters.pageNumber} 
                                pageLimit={filters.quantityPerPage} 
                                totalRecords={logData?.logsQuantity || 0} 
                                handlePageChanged={(pageNumber) => 
                                    setFilters((filters: any) => ({ ...filters, pageNumber }))
                                } />

                            <LogList logs={logData?.logs} />
                        </Container>
                    }
                </>
            </Panel>
        </Container>
    );
};

LogIndex.propTypes = {
    className: PropTypes.string
};

export default LogIndex;
