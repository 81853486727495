import React from 'react';
import ReactDOM from 'react-dom';

import { HashHistory } from 'history';

import App from '../src/components/templates/App/App';

import './index.scss';

declare global {
    interface Window {
        renderUatu: any;
        unmountUatu: any;
    }
};

window.renderUatu = (containerId: string, history: HashHistory, store: any): Boolean => {
    ReactDOM.render(
        <App history={history} />,
        document.getElementById(containerId)
    );

    return true;
};

window.unmountUatu = (containerId: string): Boolean => {
    const domElement = document.getElementById(containerId) as Element;
    ReactDOM.unmountComponentAtNode(domElement);
    return true;
};

if (document.getElementById('uatu-container') == null) {
    ReactDOM.render(
        <React.StrictMode>
            <App showMenu={process.env.NODE_ENV === 'development'} />
        </React.StrictMode>,
        document.getElementById('root')
    );
};
