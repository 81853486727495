import React from "react";
import PropTypes from "prop-types";

import { translation as t, getCurrentLanguage } from "@optimuminterfaces/revex-react-components/dist/translation/index";

import { Table } from "@optimuminterfaces/revex-react-components/dist/components";
import { Log } from "../../../../models/Log";

import styles from './List.module.scss';

interface LogListProps {
    className?: string,
    logs?: Log[] | null | undefined,
    Container?: any,
};

const formatDate = (date: string | Date | number | null | undefined, dateFormatOptions?: any) => {
    try{
        if(!date){
            return date;
        }

        let formatOptions: any = {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit", 
            hour: 'numeric',
            minute: 'numeric',
            hour12: false
        };
    
        const language: string = (getCurrentLanguage() || 'pt-BR');
        switch(language){
            // case 'pt-BR':
            // case 'pt-PT':
            //     formatOptions = {
            //         ...formatOptions
            //     };
            //     break;
    
            case 'en-US':
                formatOptions = {
                    ...formatOptions, 
                    year: "4-digit",
                };
                break;
        }
    
        let dateObj = date;
        if(!(dateObj instanceof Date)){
            dateObj = new Date(date);
        }
    
        if (!!dateFormatOptions) {
            formatOptions = { ...formatOptions, ...dateFormatOptions };
        }
    
        return new Intl.DateTimeFormat(language, formatOptions).format(dateObj);
    }catch(e){
        return date;
    }
};

const LogList = ({
    className,
    logs,
    Container = 'div'
}: LogListProps) => {

    const renderLogs = (logs: Log[] | null | undefined, emptyListColSpan: number) => {
        if (!!logs && logs.length > 0) {
            return (
                logs.map((log, rowIndex) => (
                    <Table.BodyItem key={`rowIndex-${rowIndex}`}>
                        <Table.BodyItem.Item>{formatDate(log.created)}</Table.BodyItem.Item>
                        <Table.BodyItem.Item>{log.logType?.name}</Table.BodyItem.Item>
                        <Table.BodyItem.Item>{log.createdBy}</Table.BodyItem.Item>
                        <Table.BodyItem.Item>{log.description}</Table.BodyItem.Item>
                    </Table.BodyItem>
                ))
            );
        }

        return (
            <Table.BodyItem>
                <Table.BodyItem.Item colSpan={emptyListColSpan}>
                    <Container style={{ textAlign: 'center' }}>
                        Não existem registros
                    </Container>
                </Table.BodyItem.Item>
            </Table.BodyItem>
        );
    };

    return (
        <Container className={`${className || ''} ${styles['log-list-rt']}`.trim()}>
            <Table className={styles['table-logs']} 
                striped 
                primary>

                <Table.Header>
                    <Table.HeaderItem>
                        <Table.HeaderItem.Item>{t('GENERIC.TEXT.DATE')}</Table.HeaderItem.Item>
                        <Table.HeaderItem.Item>{t('GENERIC.TEXT.TYPE')}</Table.HeaderItem.Item>
                        <Table.HeaderItem.Item>{t('UATU.TEXT.CREATED_BY')}</Table.HeaderItem.Item>
                        <Table.HeaderItem.Item>{t('GENERIC.TEXT.DESCRIPTION')}</Table.HeaderItem.Item>
                    </Table.HeaderItem>
                </Table.Header>

                <Table.Body>
                    {renderLogs(logs, 4)}
                </Table.Body>
            </Table>

        </Container>
    );
};

LogList.propTypes = {
    className: PropTypes.string,
    logs: PropTypes.array
};

export default LogList;
